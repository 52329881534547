import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

const Login = () => import('@/components/Login');

const Home = () => import('@/components/Home');
const LiveWebinar = () => import('@/components/Live-webinar');
const OnDemandWebinar = () => import('@/components/On-demand-webinar');
const Loadpage = () => import('@/components/Loadpage');
const SyllabusCourse = () => import('@/components/Syllabus-course');
const InteractiveContents = () => import('@/components/Interactive-contents');
const EflmEvents = () => import('@/components/Eflm-events');

const Search = () => import('@/components/Search');
const PersonalArea = () => import('@/components/Personal-area');

var mode = (process.env.NODE_ENV != 'development') ? createWebHistory('/site/') : createWebHashHistory('/site/')

export const createVueRouter = (app) => {
   const router = createRouter({
      history: mode,
      //base: '/site/',

      scrollBehavior (to, from, savedPosition) {
         /*return new Promise((resolve, reject) => {
            setTimeout(() => {
               resolve({ x: 0, y: 0 })
            }, 500)
         })*/
         document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      },

      routes: [
         //{ path: '/', name: 'root', meta: { requiresEvent: false, requiresAuth: false }, components:{main: Home  }},
         { path: '/autologin/:contatto', name: 'autologin', meta: { requiresAuth: false }, components:{main: Home  } }, 
         { path: '/login', name: 'login', meta: { requiresAuth: false }, components:{main: Login  } }, 

         { path: '/', name: 'home', meta: { requiresAuth: false }, components:{main: Home  } }, 
         { path: '/live-webinar', name: 'webinar-live', meta: { requiresAuth: false, needUser: true }, components:{main: LiveWebinar  } }, 
         { path: '/on-demand-webinar', name: 'on-demand-live', meta: { requiresAuth: false, needUser: true }, components:{main: OnDemandWebinar  } }, 
         { path: '/syllabus-course', name: 'syllabus-course', meta: { requiresAuth: false, needUser: true }, components:{main: SyllabusCourse  } }, 
         { path: '/interactive-contents', name: 'interactive-contents', meta: { requiresAuth: false, needUser: true }, components:{main: InteractiveContents  } }, 
         { path: '/eflm-events', name: 'eflm-events', meta: { requiresAuth: false, needUser: true }, components:{main: EflmEvents  } }, 

         { path: '/search/:val', name: 'search', meta: { requiresAuth: false }, components:{main: Search } }, 
         { path: '/personal-area', name: 'personal-area', meta: { requiresAuth: false }, components:{main: PersonalArea } }, 

         //{ path: '/autologin/:contatto', name: 'autologin', meta: {requiresAuth: true, requiresEvent: true}, components:{main: Home }  },
         //{ path: '/autologin/:contatto/:evento', name: 'autologin-evento', meta: {requiresAuth: true, requiresEvent: true}, components:{main: Home }  },

         { path: '/page/:filename', name: 'inside',  meta: { inside:'inside', nav: true }, components:{main: Loadpage },props: {main: true} }, 

         { path: "/:catchAll(.*)", redirect: '/' } 
      ]
   })

   router.beforeEach(async (to, from, next) => {
      var teras= app.config.globalProperties.$teras
      let error= false
      teras.app.drawer = false
      teras.check.autoLoginBegin= false
      //to.query contiene le variabili passate in modo standard es. ?variabile=valore
      //to.params contiene le variabili passate in vuejs passate in base a routes[]
      console.log("\nbeforeEach \ncheckFirst: "+router.checkFirst+"\nfrom: "+from.path+"\nto: "+to.path+"\n\n")
      teras.router.from = from.path
      teras.router.to = to.path

      teras.app.routeAfterLogin= to.path=='/login' ? from.path : ''

      if (!teras.check.first){
         error= true
         console.log("SONO RICHIESTE LE OPERAZIONI DI PRIMO AVVIO")
         teras.event.emit('checkFirst', to)
      }

      if(!error && !teras.check.autoLoginBegin && ["autologin"].includes(to.name)){
         console.log("ARRIVO A FARE AUTOLOGIN")
         error = true
         teras.check.autoLoginBegin= true
         await autoLogin(to.params, to.name)
      }
      
      console.log("AUTH FROM ROUTER"+teras.check.auth)
      if (!error && to.matched.some(record => record.meta.requiresAuth) && !teras.check.auth){
         console.log("È RICHIESTO UTENTE E NON È ANCORA VERIFICATO")
         teras.event.emit('checkAuth', from, to)
         error= true
      }

      if(!error){
         teras.app.ready = true
         next()
         teras.event.emit('routerChange', to)
      } else{
         next(false)
      }
   })

   //trackRouter(router); 

   return router
}