import main from './main'

export class teras extends main{

   constructor (args) {
      super(args)
      this.name = 'www.eflm-elearning.eu.site'
      this.version = '1.0.1'
      this.urlsite = (process.env.NODE_ENV != 'development') ? document.location.origin + '/site/' : ''
      this.page = {
         title: 'Home'
      }
      this.pagename= ""

      this.apiUrl = 'https://api.mzevents.it/201806/'
      this.mysql = {
         composer:'db',
         adapter:'mz',
         database:'eflm_elearning'
      }
      this.apiUrlContatto = 'https://api.terasmm.it/201806/'
      this.mysqlContatto = {
         composer:'db',
         adapter:'contabo01',
         database:'eflm_academy'
      }
   
      this.liveWebinars= []
      this.onDemandWebinars= []
      this.eflmEvents= []
      this.lezioniSyllabus= []

      this.corsi= []

      this.iscrizioniUtenteEventi= []

      this.loaded= false
      this.minWidthMobile= 760
      this.utente = {}
      super.init()
      return this
   }

 
}
export default new teras
