<template>
   <div v-loading.fullscreen.lock="!$teras.app.ready" :class="'device-'+$teras.app.device">
      
      <el-drawer :show-close="false" @close="drawerClose" v-model="$teras.app.drawer" direction="ltr" :size="($teras.app.device=='desktop') ? '480px' : '80%'">
         <site-navbar class="site-drawer" />
      </el-drawer>

      <div class="header-top" v-if="$teras.app.device!='desktop'">
         <site-header />
      </div>

      <div class="app-main row">
         <div class="col s12 m3" v-if="$teras.app.device=='desktop'">
            <site-navbar />
         </div>
         <div id="main" class="col s12 yo-text-center yo-margin-bottom" :class="$teras.app.device=='desktop' ? 'm6' : 'm12'">
            <div class="yo-max-w">
               <router-view name="main"></router-view>
            </div>
         </div><!-- end main -->
         <div class="col s12" :class="$teras.app.device=='desktop' ? 'm3' : 'm12'">
            <site-footer />
         </div>
      </div><!-- end container -->
   </div> <!-- end app -->
</template>

<script>
import { defineAsyncComponent } from "vue"
import { ElDrawer } from "element-plus"
export default {
   name: 'app',
   components: {
      SiteHeader: defineAsyncComponent(() => import("@/components/Site-header.vue")),
      SiteNavbar: defineAsyncComponent(() => import("@/components/Site-navbar.vue")),
      SiteFooter: defineAsyncComponent(() => import("@/components/Site-footer.vue")),

      ElDrawer
   },
   data() {
      return {
         loading:{}
      }
   },
   created(){

      this.$teras.event.on('notify', (titolo,messaggio,tipo) => {
         titolo = (typeof messaggio == 'string') ? titolo : 'Errore non specificato'
         messaggio = (typeof messaggio == 'string') ? messaggio : 'Siamo spiacenti si è verificato un errore, la preghiamo di verificare i dati inseriti!'
         tipo = (typeof tipo == 'string') ? tipo : 'error'
         this.$notify({type: tipo, title: titolo, message: messaggio})
      })

      this.$teras.event.on('message', (messaggio,tipo) => {
         this.$message[tipo](messaggio)
      })

      this.$teras.event.on('loading', (messaggio) => {
         console.log(typeof messaggio)
         messaggio = (typeof messaggio == 'string') ? messaggio : 'Caricamente in corso, non ricaricare la pagina.....'
         console.log("messaggio: "+messaggio)
         this.loading = this.$loading({lock: true, text: messaggio, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      })
      this.$teras.event.on('loadingclose', () => {
         this.loading.close()
      })
   },
   methods: {
      drawerClose(){
         var menuIcon= document.getElementById('menu-mobile-icon')
         if(menuIcon!=undefined){
            menuIcon.classList.remove('active')
         }
      }
   }
}
</script>