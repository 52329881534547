import { createApp } from 'vue/dist/vue.esm-bundler'; 
import {h, reactive} from 'vue'
import App from './App.vue'

import VueGtag from "vue-gtag"


/* Inizio Router */
import { createVueRouter } from './router'
/* Fine Router */

/* Inizio ElementPlus */
import { ElLoading, ElMessage, dayjs } from 'element-plus';
//Importa il primo giorno della settimana nei picker al Lunedì
dayjs().$locale().weekStart = 1;
import 'element-plus/theme-chalk/index.css';
//import locale from 'element-plus/lib/locale/lang/it'
/* Fine ElementPlus */

/* Inizio Luxon */
//https://moment.github.io/luxon/#/moment
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "en"
luxonSettings.defaultZone= "Europe/Rome"
/* Fine Luxon */

/* Inizio TerAs */
import terasImp from './teras'
var teras= reactive(terasImp)
teras.luxon= luxon
import axios from './teras/tAxios'
import aes from './teras/aes'

import './css/big.css'
/* Fine TerAs */

/* Inizio Storage */
import storage from './teras/tStorage' 
/* Fine Storage */

/* Inizio TerAs-event */
teras.event.on('checkAuth', async (from,to) =>{
   console.log("CHECK AUTH")
   if(storage.exist('utente')){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      var utente= storage.load('utente')
      teras.event.emit('login', utente.email, utente.password)
   }else{
      teras.check.auth = false
      teras.event.emit('errore',-1,'È necessario effettuare l\'accesso prima di poter accedere alla pagina')
      if(from.path!='/login')
         router.replace('/login')
   }
})
teras.event.on('checkFirst', async function(to) {
   teras.check.first = true
   //Se è presente un utente in storage lo carico in modo da averlo a disposizione fin da subito in tutti i componenti
   if(storage.exist('utente')){
      var utente= storage.load('utente')
      teras.event.emit('login', utente.email, utente.password)
   }
   console.log('check first')
   //if(router.options.history.location!=to.path)
      router.replace(to.path)
})
teras.event.on('appReady', function() {
   console.log("appReady appReady appReady appReady appReady appReady appReady")
   router.push(teras.app.routeInitial)
})
teras.event.on('appExit', function() {
})

teras.event.on('login', async (email, pwd, to) => {
   var utente= await axios.getContatto('FetchRow', 
      `SELECT id, email, password, cognome, nome FROM fad_contatto WHERE email= →email AND password= →pwd`, {email: email, pwd: pwd})
   
   if(utente.result.id!=undefined){
      teras.utente= utente.result
      teras.check.auth = true
      storage.save("utente",teras.utente)
      if(to!=undefined) router.replace(to)
   } else{
      teras.event.emit('message', 'Wrong credentials!', 'error')
   }
 })
 teras.event.on('logout', function(to) {
   teras.utente = {}
   storage.remove('utente')
   teras.check.auth = false
   if(to!=undefined){
      router.replace(to)
   } /*else{
      router.replace('/login')
   }*/
 })

teras.event.on('setSeoInformation', async function(page){
   new Promise((resolve) => {
      teras.event.emit('removeSeoInformationFromPrevPage', resolve)
   }).then(async (value) => {
      //Imposto il meta title e description per la pagina statica recuperata
      for(var i=0; i<page.head.children.length; i++){
         if(page.head.children[i].attributes[0].nodeName=='title'){
            console.log('SEO title: ' +page.head.children[i].attributes[0].nodeValue)
            var meta = document.createElement('meta');
            meta.title= page.head.children[i].attributes[0].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
         if(page.head.children[i].attributes[0].nodeName=='name' && page.head.children[i].attributes[0].nodeValue=='description' && 
            page.head.children[i].attributes[1]!=undefined && page.head.children[i].attributes[1].nodeName=='content'){
            console.log('SEO description: ' +page.head.children[i].attributes[1].nodeValue)
            var meta = document.createElement('meta');
            meta.name='description'
            meta.content = page.head.children[i].attributes[1].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
      }
   })
})
teras.event.on('removeSeoInformationFromPrevPage', function(callback){
   //Salvo dentro l'array nodeToRemove i tag meta della pagina statica precedente che sono da rimuovere per inserire quelli nuovi
   var nodeToRemove= []
   for(var i=0; i<document.getElementsByTagName('head')[0].children.length; i++){
      var node= document.getElementsByTagName('head')[0].children[i]
      if(node.localName=='meta' && node.attributes[0].nodeName=='title'){
         nodeToRemove.push(node)
      }
      if(node.localName=='meta' && node.name=='description'){
         nodeToRemove.push(node)
      }
   }
   for(var i=0; i<nodeToRemove.length; i++){
      document.getElementsByTagName('head')[0].removeChild(nodeToRemove[i])
   }
   if(callback!=undefined){
      //console.log('callback')
      callback(true)
   }
})
window.autoLogin =  function(params,name){
   return new Promise(async (resolve, reject) => {
      //console.log("PARAMS ")
      //console.log(params)

      teras.utente = {}
      storage.remove('utente')
      teras.check.auth = false

      let checkUtente = await axios.getContatto('fetchRow',
         'SELECT id, cognome, nome, email, password FROM fad_contatto WHERE id=CAST(AES_DECRYPT(UNHEX(→encrypted), password) as CHAR)',{encrypted: params.contatto})
      console.log(checkUtente)
      
      if(checkUtente.result.id!=undefined){
         teras.event.emit('login', checkUtente.result.email, checkUtente.result.password, '/')
      } else{
         console.log('AUTOLOGIN FALLITO')
         router.replace('/')
      }
      
      resolve(true)
   })
}
window.addEventListener('resize',() => { 
   teras.getDevice()
   teras.event.emit('appResize')
})
window.addEventListener('scroll',() => { 
   teras.getDevice()
   teras.event.emit('appScroll')
})

/* Fine TerAs-event */

/* Inizio Vue-3 */

const app= createApp({
   /*data() {
      
   },*/
   render: () => h(App)
})

app.config.globalProperties.$teras= teras
app.config.globalProperties.$axios = axios
app.config.globalProperties.$aes= aes
app.config.globalProperties.$luxon= luxon
app.config.globalProperties.$storage = storage

const router = createVueRouter(app)
app.use(router)
app.use(VueGtag, {
   config: { id: "G-N843DMM5K1" },
   appName: 'Eflm e-learning site',
   enabled: process.env.NODE_ENV == 'production'
}, router)
app.use(ElLoading)
app.use(ElMessage)

app.mount('#app')

teras.vueAppInstance= app

/* Fine Vue-3 */
